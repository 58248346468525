/* You can add global styles to this file, and also import other style files */
/* THESE STYLES APPEAR ON ALL WEBSITES */
@import "./styles-branding.scss";
@import "./styles-material.scss";
@import "./styles-wysiwyg.scss";
@import "./styles-pipelines.scss";
@import "./styles-cookie-popup.scss";
@import "./responsive.scss";

// * {
//     transition: all 0.5s;
// }

noscript {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
mat-icon {
    font-family: "Material Icons", "Material Icons";
}

// https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzcwNzA3MCIsIj9lcjwjZDRkNGQ0IiwiO2VyPCM1MzUzNTN$LCIlPmBePCNlYTU5MDAiLCI~ZXI8I2Y5Y2RiMyIsIjtlcjwjZTEzZTAwfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyMjIyMjJ$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz5mYWxzZSwidmVyc2lvbj4xM30=

a {
    color: inherit;
}

.error {
    color: #ff0000;
}

.fadeInOpacity {
    animation-name: fadeInOpacity;
    animation-duration: 1s;
    opacity: 1;
}
.fadeOutOpacity {
    animation-name: fadeOutOpacity;
    animation-duration: 1s;
    opacity: 0;
}

.shade-out {
    animation-name: shadeOut;
    animation-duration: 5s;
    filter: brightness(0.3);
}

@keyframes fadeInOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadeOutOpacity {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
@keyframes fadeInShade {
    from {
        background: rgba(0, 0, 0, 0);
    }

    to {
        background: rgba(0, 0, 0, 0.75);
    }
}

@keyframes fadeOutShade {
    from {
        background: rgba(0, 0, 0, 0.75);
    }

    to {
        background: rgba(0, 0, 0, 0);
    }
}
@keyframes shadeOut {
    from {
        filter: brightness(1);
    }
    to {
        filter: brightness(0.3);
    }
}

.spacer {
    flex-grow: 1;
}

.clear {
    clear: both;
}
.white-text {
    * {
        color: #fff;
    }
}

.hide-input {
    .mat-form-field-flex,
    .mat-form-field-underline {
        display: none;
    }
    &.ng-valid {
        .mat-form-field-wrapper {
            display: none;
        }
    }
}

.always-hidden {
    display: none !important;
    visibility: hidden;
}

// this removes the background and text change caused by autofill
mat-form-field {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition:
            background-color 5000s ease-in-out 0s,
            color 5000s ease-in-out 0s;
    }
}

app-prize-game-slot-machine {
    .boxes {
        * {
            display: block;
        }
    }
}

.form .mat-checkbox-layout {
    white-space: initial;
}
.mat-valid {
    color: #fff;
    background-color: #1c8b18;
}
.mat-valid-text {
    color: #1c8b18;
}

.mat-stepper-label-position-bottom {
    @include respond-below(xs) {
        .mat-horizontal-stepper-header {
            padding: 24px 10px !important;
        }
        .mat-stepper-horizontal-line {
            min-width: initial;
        }
    }
    @include respond-below(xxs) {
        .mat-horizontal-stepper-header {
            padding: 24px 5px !important;
        }
        .mat-stepper-horizontal-line {
            min-width: initial;
        }
    }
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
    background-color: #979797 !important;
}

/* ********************************************* */

/* Pyro fireworks */
@import "./pyro.scss";
