
.pyro {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.pyro > .before, .pyro > .after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
    -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  }
  
  .pyro > .after {
    -moz-animation-delay: 1.25s, 1.25s, 1.25s;
    -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
    -o-animation-delay: 1.25s, 1.25s, 1.25s;
    -ms-animation-delay: 1.25s, 1.25s, 1.25s;
    animation-delay: 1.25s, 1.25s, 1.25s;
    -moz-animation-duration: 1.25s, 1.25s, 6.25s;
    -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
    -o-animation-duration: 1.25s, 1.25s, 6.25s;
    -ms-animation-duration: 1.25s, 1.25s, 6.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
  }
  
  @-webkit-keyframes bang {
    to {
      box-shadow: 82px 61.3333333333px #00ffa2, 124px -63.6666666667px deepskyblue, 216px 68.3333333333px #00ff77, 49px -254.6666666667px #00ffbf, -148px -378.6666666667px #ffc400, -72px -142.6666666667px #ff00d5, 13px -291.6666666667px #d0ff00, -213px 12.3333333333px #0088ff, 55px -400.6666666667px #f700ff, 104px -235.6666666667px #4000ff, 166px -412.6666666667px #ff1e00, 206px 55.3333333333px #ea00ff, 243px -61.6666666667px #00ff8c, 166px -70.6666666667px #c800ff, -17px 50.3333333333px #ff00f7, -32px -112.6666666667px #ff7300, -37px -327.6666666667px #ff0011, -91px 60.3333333333px #2600ff, 33px 52.3333333333px #ff0033, -122px -291.6666666667px #ff6a00, -23px -24.6666666667px #00ff59, -144px -318.6666666667px #e100ff, -198px -364.6666666667px #ff005e, -226px 3.3333333333px #00ffa2, 110px -135.6666666667px #8c00ff, -109px -136.6666666667px #ff00a2, 184px -396.6666666667px #00ff1e, 37px -314.6666666667px #e1ff00, 240px 41.3333333333px #ff0022, 201px -185.6666666667px #c400ff, 53px 0.3333333333px #00f7ff, 123px -383.6666666667px #ff00b3, 55px -215.6666666667px #f7ff00, 138px -143.6666666667px #00c4ff, 204px 14.3333333333px #15ff00, -140px -106.6666666667px #62ff00, 29px -282.6666666667px #1e00ff, -71px -43.6666666667px #ff001a, -201px -247.6666666667px #ff00b7, 81px -11.6666666667px #9100ff, -96px -275.6666666667px #ff00cc, 236px -85.6666666667px #8000ff, -225px -241.6666666667px #ff0095, 33px -122.6666666667px #0009ff, 236px -248.6666666667px #00ffc8, -67px -80.6666666667px #00ff11, 54px -201.6666666667px #001aff, 189px 23.3333333333px #00ff95, -109px -193.6666666667px #1eff00, -1px -175.6666666667px #00ff95, 213px -30.6666666667px #77ff00;
    }
  }
  @-moz-keyframes bang {
    to {
      box-shadow: 82px 61.3333333333px #00ffa2, 124px -63.6666666667px deepskyblue, 216px 68.3333333333px #00ff77, 49px -254.6666666667px #00ffbf, -148px -378.6666666667px #ffc400, -72px -142.6666666667px #ff00d5, 13px -291.6666666667px #d0ff00, -213px 12.3333333333px #0088ff, 55px -400.6666666667px #f700ff, 104px -235.6666666667px #4000ff, 166px -412.6666666667px #ff1e00, 206px 55.3333333333px #ea00ff, 243px -61.6666666667px #00ff8c, 166px -70.6666666667px #c800ff, -17px 50.3333333333px #ff00f7, -32px -112.6666666667px #ff7300, -37px -327.6666666667px #ff0011, -91px 60.3333333333px #2600ff, 33px 52.3333333333px #ff0033, -122px -291.6666666667px #ff6a00, -23px -24.6666666667px #00ff59, -144px -318.6666666667px #e100ff, -198px -364.6666666667px #ff005e, -226px 3.3333333333px #00ffa2, 110px -135.6666666667px #8c00ff, -109px -136.6666666667px #ff00a2, 184px -396.6666666667px #00ff1e, 37px -314.6666666667px #e1ff00, 240px 41.3333333333px #ff0022, 201px -185.6666666667px #c400ff, 53px 0.3333333333px #00f7ff, 123px -383.6666666667px #ff00b3, 55px -215.6666666667px #f7ff00, 138px -143.6666666667px #00c4ff, 204px 14.3333333333px #15ff00, -140px -106.6666666667px #62ff00, 29px -282.6666666667px #1e00ff, -71px -43.6666666667px #ff001a, -201px -247.6666666667px #ff00b7, 81px -11.6666666667px #9100ff, -96px -275.6666666667px #ff00cc, 236px -85.6666666667px #8000ff, -225px -241.6666666667px #ff0095, 33px -122.6666666667px #0009ff, 236px -248.6666666667px #00ffc8, -67px -80.6666666667px #00ff11, 54px -201.6666666667px #001aff, 189px 23.3333333333px #00ff95, -109px -193.6666666667px #1eff00, -1px -175.6666666667px #00ff95, 213px -30.6666666667px #77ff00;
    }
  }
  @-o-keyframes bang {
    to {
      box-shadow: 82px 61.3333333333px #00ffa2, 124px -63.6666666667px deepskyblue, 216px 68.3333333333px #00ff77, 49px -254.6666666667px #00ffbf, -148px -378.6666666667px #ffc400, -72px -142.6666666667px #ff00d5, 13px -291.6666666667px #d0ff00, -213px 12.3333333333px #0088ff, 55px -400.6666666667px #f700ff, 104px -235.6666666667px #4000ff, 166px -412.6666666667px #ff1e00, 206px 55.3333333333px #ea00ff, 243px -61.6666666667px #00ff8c, 166px -70.6666666667px #c800ff, -17px 50.3333333333px #ff00f7, -32px -112.6666666667px #ff7300, -37px -327.6666666667px #ff0011, -91px 60.3333333333px #2600ff, 33px 52.3333333333px #ff0033, -122px -291.6666666667px #ff6a00, -23px -24.6666666667px #00ff59, -144px -318.6666666667px #e100ff, -198px -364.6666666667px #ff005e, -226px 3.3333333333px #00ffa2, 110px -135.6666666667px #8c00ff, -109px -136.6666666667px #ff00a2, 184px -396.6666666667px #00ff1e, 37px -314.6666666667px #e1ff00, 240px 41.3333333333px #ff0022, 201px -185.6666666667px #c400ff, 53px 0.3333333333px #00f7ff, 123px -383.6666666667px #ff00b3, 55px -215.6666666667px #f7ff00, 138px -143.6666666667px #00c4ff, 204px 14.3333333333px #15ff00, -140px -106.6666666667px #62ff00, 29px -282.6666666667px #1e00ff, -71px -43.6666666667px #ff001a, -201px -247.6666666667px #ff00b7, 81px -11.6666666667px #9100ff, -96px -275.6666666667px #ff00cc, 236px -85.6666666667px #8000ff, -225px -241.6666666667px #ff0095, 33px -122.6666666667px #0009ff, 236px -248.6666666667px #00ffc8, -67px -80.6666666667px #00ff11, 54px -201.6666666667px #001aff, 189px 23.3333333333px #00ff95, -109px -193.6666666667px #1eff00, -1px -175.6666666667px #00ff95, 213px -30.6666666667px #77ff00;
    }
  }
  @-ms-keyframes bang {
    to {
      box-shadow: 82px 61.3333333333px #00ffa2, 124px -63.6666666667px deepskyblue, 216px 68.3333333333px #00ff77, 49px -254.6666666667px #00ffbf, -148px -378.6666666667px #ffc400, -72px -142.6666666667px #ff00d5, 13px -291.6666666667px #d0ff00, -213px 12.3333333333px #0088ff, 55px -400.6666666667px #f700ff, 104px -235.6666666667px #4000ff, 166px -412.6666666667px #ff1e00, 206px 55.3333333333px #ea00ff, 243px -61.6666666667px #00ff8c, 166px -70.6666666667px #c800ff, -17px 50.3333333333px #ff00f7, -32px -112.6666666667px #ff7300, -37px -327.6666666667px #ff0011, -91px 60.3333333333px #2600ff, 33px 52.3333333333px #ff0033, -122px -291.6666666667px #ff6a00, -23px -24.6666666667px #00ff59, -144px -318.6666666667px #e100ff, -198px -364.6666666667px #ff005e, -226px 3.3333333333px #00ffa2, 110px -135.6666666667px #8c00ff, -109px -136.6666666667px #ff00a2, 184px -396.6666666667px #00ff1e, 37px -314.6666666667px #e1ff00, 240px 41.3333333333px #ff0022, 201px -185.6666666667px #c400ff, 53px 0.3333333333px #00f7ff, 123px -383.6666666667px #ff00b3, 55px -215.6666666667px #f7ff00, 138px -143.6666666667px #00c4ff, 204px 14.3333333333px #15ff00, -140px -106.6666666667px #62ff00, 29px -282.6666666667px #1e00ff, -71px -43.6666666667px #ff001a, -201px -247.6666666667px #ff00b7, 81px -11.6666666667px #9100ff, -96px -275.6666666667px #ff00cc, 236px -85.6666666667px #8000ff, -225px -241.6666666667px #ff0095, 33px -122.6666666667px #0009ff, 236px -248.6666666667px #00ffc8, -67px -80.6666666667px #00ff11, 54px -201.6666666667px #001aff, 189px 23.3333333333px #00ff95, -109px -193.6666666667px #1eff00, -1px -175.6666666667px #00ff95, 213px -30.6666666667px #77ff00;
    }
  }
  @keyframes bang {
    to {
      box-shadow: 82px 61.3333333333px #00ffa2, 124px -63.6666666667px deepskyblue, 216px 68.3333333333px #00ff77, 49px -254.6666666667px #00ffbf, -148px -378.6666666667px #ffc400, -72px -142.6666666667px #ff00d5, 13px -291.6666666667px #d0ff00, -213px 12.3333333333px #0088ff, 55px -400.6666666667px #f700ff, 104px -235.6666666667px #4000ff, 166px -412.6666666667px #ff1e00, 206px 55.3333333333px #ea00ff, 243px -61.6666666667px #00ff8c, 166px -70.6666666667px #c800ff, -17px 50.3333333333px #ff00f7, -32px -112.6666666667px #ff7300, -37px -327.6666666667px #ff0011, -91px 60.3333333333px #2600ff, 33px 52.3333333333px #ff0033, -122px -291.6666666667px #ff6a00, -23px -24.6666666667px #00ff59, -144px -318.6666666667px #e100ff, -198px -364.6666666667px #ff005e, -226px 3.3333333333px #00ffa2, 110px -135.6666666667px #8c00ff, -109px -136.6666666667px #ff00a2, 184px -396.6666666667px #00ff1e, 37px -314.6666666667px #e1ff00, 240px 41.3333333333px #ff0022, 201px -185.6666666667px #c400ff, 53px 0.3333333333px #00f7ff, 123px -383.6666666667px #ff00b3, 55px -215.6666666667px #f7ff00, 138px -143.6666666667px #00c4ff, 204px 14.3333333333px #15ff00, -140px -106.6666666667px #62ff00, 29px -282.6666666667px #1e00ff, -71px -43.6666666667px #ff001a, -201px -247.6666666667px #ff00b7, 81px -11.6666666667px #9100ff, -96px -275.6666666667px #ff00cc, 236px -85.6666666667px #8000ff, -225px -241.6666666667px #ff0095, 33px -122.6666666667px #0009ff, 236px -248.6666666667px #00ffc8, -67px -80.6666666667px #00ff11, 54px -201.6666666667px #001aff, 189px 23.3333333333px #00ff95, -109px -193.6666666667px #1eff00, -1px -175.6666666667px #00ff95, 213px -30.6666666667px #77ff00;
    }
  }
  @-webkit-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0;
    }
  }
  @-moz-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0;
    }
  }
  @-o-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0;
    }
  }
  @-ms-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0;
    }
  }
  @keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0;
    }
  }
  @-webkit-keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
  @-moz-keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
  @-o-keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
  @-ms-keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
  @keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }