.page,
app-preview-entrant {
    .mat-form-field-label {
        color: inherit;
    }
    .mat-form-field-appearance-outline {
        .mat-form-field-outline {
            color: inherit;
        }
    }
    .mat-checkbox-frame {
        border-color: inherit;
    }
}
