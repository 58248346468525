@import "./responsive.scss";

.cookie-shadow {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: rgb(40 40 40 / 69%);
    z-index: 1000;
    animation: fadeInOpacity;

    &.closed {
        animation: fadeOutOpacity;
    }

    &.preview {
        position: initial;
        display: block;
        width: 100%;

        .cookie-popup {
            width: 100% !important;
        }
    }

    .cookie-popup {
        background: #000;
        color: #fff;
        padding: 1.5rem;

        width: 50%;
        max-width: 1000px;
        box-sizing: border-box;
        overflow: auto;
        max-height: 100%;

        @include respond-below(lg) {
            width: 60%;
        }
        @include respond-below(md) {
            width: 70%;
        }
        @include respond-below(sm) {
            width: 100%;
            padding: 0 1rem 3rem 1rem;
            overflow-y: scroll;
            height: 100%;

            .cookie-list {
                .cookie-option {
                    justify-content: space-between !important;
                    .mat-slide-toggle {
                        width: 25%;
                    }
                    .label {
                        width: calc(75%);
                        margin: 0 !important;
                    }
                }
            }
        }
        @include respond-below(xs) {
            .cookie-footer {
                flex-wrap: wrap;
                .cookie-option-button {
                    width: 100% !important;
                    margin: 0.5rem 0 !important;
                }
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-align: center;
        }
    }
}

.cookie-settings-change {
    i {
        font-family: "Material Icons";
        font-style: normal;
    }
    i {
        font-size: 30px;
        overflow: visible;
        line-height: 75px;
        width: 75px;
        height: 75px;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }
    }
}
