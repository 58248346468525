// From CKEDITOR
.text-huge {
    font-size: 1.8em;
}

.text-big {
    font-size: 1.4em;
}

.text-small {
    font-size: 0.85em;
}

.text-tiny {
    font-size: 0.7em;
}

// .content * {
//     font-family: inherit !important;
// }

